<template>
  <div>
    <ResultCard
      v-for="(result, i) in results"
      :key="i"
      :name="result.teamName"
      :to="getDetailsLink(result.teamId)"
      :data-id="result.teamId"
      :highlighted="idHighlighted === result.teamId"
      @clickMemberImg.prevent.stop="!hideCompareButton ? $emit('compare', result.teamId) : null"
    >
      <template v-slot:info>
        <div class="result-card__info-item text-with-icon" v-if="result.number">
          <Icon
            class="icon color-muted"
            name="basketball-jersey"
            size="xs"
          />&nbsp;<span>№{{ result.number }}</span>
        </div>
        <div class="result-card__info-item text-with-icon" v-if="result.genderAgeNominationName">
          <Icon
            class="icon color-muted"
            name="pie-chart"
            size="xs"
          />&nbsp;<span>{{ result.genderAgeNominationName }}</span>
        </div>
      </template>
      <template v-if="!hideCompareButton" v-slot:action>
        <CompareButton
          :active="compareList.indexOf(result.teamId) !== -1"
          @toggle="$emit('compare', result.teamId)"
        ></CompareButton>
      </template>
      <template v-slot:cols>
        <div class="result-card__col" style="z-index: 1">
          <ResultPosition
            class="text-lg"
            :position="result[positionByKey]"
            :status="result.status"
            :leadersCount="absoluteLeadersCount"
            :isRelay="true"
          >
            <div class="text-xs mt-4"><b>{{ $t('result.position') }} ({{ positionByText.toLowerCase() }})</b></div>
          </ResultPosition>
        </div>
        <div class="result-card__col">
          <div
            :is="result.timeOffset && result.timeOffset !== '00:00' ? 'TimeOffset' : 'div'"
            :offset="result.timeOffset"
            :result="result.resultByCalcMethod"
            style="position: relative; z-index: 1;"
          >
            <span class="text-lg">
              {{ result.timeResultByCalcMethod || '--' }}
            </span>
            <div class="text-xs mt-4"><b>{{ $t('result.result') }}</b></div>
          </div>
        </div>
        <div class="result-card__col">
          <div class="text-lg">{{ result.delayByCalcMethod ? `+${result.delayByCalcMethod}` : '--' }}</div>
          <div class="text-xs mt-4"><b>{{ $t('result.gap') }}</b></div>
        </div>
      </template>
    </ResultCard>
  </div>
</template>

<script>
import resultsMixin from './resultsMixin';

export default {
  name: 'RelayResultsCompact',
  mixins: [resultsMixin],
  props: {
    hideCompareButton: Boolean,
  },
  methods: {
    getDetailsLink(id) {
      const route = this.$router.resolve({
        name: 'Team',
        params: { participantId: id },
      });
      return route.href;
    },
  },
};
</script>
